@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}
